const CURRENCIES = [
  "AUD",
  "CAD",
  "CHF",
  "DKK",
  "EUR",
  "GBP",
  "HKD",
  "JPY",
  "MXN",
  "PLN",
  "QAR",
  "SGD",
  "USD",
]
export { CURRENCIES }
