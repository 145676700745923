import React from "react"
import "../styles/styles.scss"
import { apolloClient } from "utils/apolloClient"
import { ApolloProvider } from "@apollo/client"
import { UserProvider } from "contexts/UserContext"
import { ReactNotifications } from "react-notifications-component"
import "react-notifications-component/dist/scss/notification.scss"
import { init as initSentry } from "../utils/sentry"
import dynamic from "next/dynamic"
import * as Sentry from "@sentry/node"
import Error from "next/error"
import initMocks from "mocks/initMocks"
import useDisableInputNumberScroll from "../hooks/useDisableInputNumberScroll"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
initSentry()
initMocks()

function ErrorPage({ message }) {
  return (
    <Error statusCode={500} title="Something went wrong">
      <span>{message}</span>
    </Error>
  )
}

function App({ Component, pageProps }) {
  useDisableInputNumberScroll()

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <ErrorPage message={error.message} />}
    >
      <UserProvider>
        <ApolloProvider client={apolloClient}>
          <ReactNotifications />
          <ToastContainer />
          {getLayout(<Component {...pageProps} />)}
        </ApolloProvider>
      </UserProvider>
    </Sentry.ErrorBoundary>
  )
}

export default dynamic(async () => App, {
  ssr: false,
})
