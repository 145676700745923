import { graphql } from "msw"

export default graphql.mutation("ChangePayoutLineStatus", (req, res, ctx) => {
  const { id, isExcluded, excludedReason } = req.variables

  return res(
    ctx.data({
      changePayoutLineStatus: {
        id,
        isExcluded,
        excludedReason,
      },
    })
  )
})
