import { faker } from "@faker-js/faker"
import { graphql } from "msw"
import arrayOfLength from "utils/arrayOfLength"
import payoutFactory from "./payout.factory"

faker.seed(123) // WHY: Keeps data consistent

const payoutEdges = arrayOfLength(400).map(() => {
  const node = payoutFactory(faker)
  return {
    node,
  }
})
export default graphql.query("GetPayout", (req, res, ctx) => {
  const { ids } = req.variables
  const [firstId] = ids
  const filteredEdges = payoutEdges.filter(({ node }) => node.id === firstId)

  return res(
    ctx.delay(),
    ctx.data({
      payouts: {
        edges: filteredEdges,
      },
    })
  )
})
