import { useEffect } from "react"

const useEventListener = ({
  event,
  handler,
  options,
  rootElement = window,
}) => {
  const isSSR = typeof rootElement === "undefined"

  useEffect(() => {
    !isSSR && rootElement.addEventListener(event, handler, options)

    return () => {
      !isSSR && rootElement.removeEventListener(event, handler, options)
    }
  }, [handler])
}

export default useEventListener
