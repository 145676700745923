import { CURRENCIES } from "utils/currencies"

export default (faker) => {
  const isExcluded = faker.helpers.arrayElement([true, false, false])
  const excludedReason = isExcluded
    ? faker.helpers.arrayElement(["voided", "refunded", "exchanged"])
    : null
  return {
    commissionRate: faker.datatype.number({ min: 0, max: 1, precision: 0.01 }),
    commissionTotal: faker.random.numeric(2),
    currencyFrom: faker.helpers.arrayElement(CURRENCIES),
    currencyTo: faker.helpers.arrayElement(CURRENCIES),
    exchangeRate: faker.datatype.number({ min: 0, max: 1, precision: 0.01 }),
    excludedReason,
    id: faker.datatype.uuid(),
    importRate: faker.datatype.number({ min: 0, max: 1, precision: 0.01 }),
    importTotal: faker.random.numeric(2),
    isExcluded,
    name: `${faker.commerce.productAdjective()} ${faker.commerce.productMaterial()} ${faker.commerce.product()} ${faker.commerce.product()}`,
    netTotal: faker.random.numeric(3),
    orderReference: `FM${faker.random.numeric(4)}`,
    payoutId: faker.datatype.uuid(),
    quantity: 1,
    quantityReturned: 0,
    shipmentType: faker.helpers.arrayElement(["domestic", "international"]),
    shopifyCustomerId: `gid://shopify/Customer/${faker.random.numeric(11)}`,
    shopifyLineItemId: `gid://shopify/FulilmentLineIiem/${faker.random.numeric(
      11
    )}`,
    shopifyOrderId: `gid://shopify/FulfillmentOrder/${faker.random.numeric(
      11
    )}`,
    transactionTotalAmount: 250,
    vatRate: 0.2,
    vatTotal: faker.random.numeric(2),
  }
}
