import * as Sentry from "@sentry/node"

export function init() {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    })
  }
}
