import localStorage from "local-storage"

export default () => {
  if (process.env.NEXT_PUBLIC_MOCKING_AVAILABLE === "true") {
    if (localStorage.get("fm-admin-mocking")) {
      if (process.env.NEXT_IS_SERVER === "true") {
        /**
         * WHY: Fakerjs seems to be making this fall over
         * Commenting out for now as we likely don't need to have mocking for SSR
         */
        // const { server } = require("./server") // eslint-disable-line
        // server.listen()
      } else {
        const { worker } = require("./browser") // eslint-disable-line
        worker.start()
      }
    }
  }
}
