import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { getToken, deleteToken } from "services/token"
import { relayStylePagination } from "@apollo/client/utilities"
import { createUploadLink } from "apollo-upload-client"
import * as Sentry from "@sentry/node"

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_END_POINT,
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const errorHandlers = {
  UNAUTHENTICATED() {
    deleteToken()

    if (typeof window !== "undefined") {
      window.location.href = `/account/login?returnUrl=${encodeURIComponent(
        window.location.pathname
      )}`
    }
  },
  FORBIDDEN() {
    if (typeof window !== "undefined") {
      if (getToken()) {
        // Logged in
        window.location.href = "/"
      } else {
        // Not logged in
        window.location.href = `/account/login?returnUrl=${encodeURIComponent(
          window.location.pathname
        )}`
      }
    }
  },
}

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      Sentry.captureException(`Graphql error - ${message}`)
    )
    if (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code) {
      const handler = errorHandlers[graphQLErrors[0].extensions.code as string]
      if (handler) {
        console.log(`GraphQL error: ${graphQLErrors[0].message}`)
        return handler()
      }
    }
  }
})

const typePolicies = {
  Query: {
    fields: {
      designerOrders: relayStylePagination(),
      users: relayStylePagination(),
      designers: relayStylePagination(),
      leads: relayStylePagination(),
      pieces: relayStylePagination(),
      brands: relayStylePagination(),
      customerGetOwners: relayStylePagination(),
      payouts: relayStylePagination(),
      shipments: relayStylePagination(),
    },
  },
}

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
})

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_END_POINT,
  headers: {
    "keep-alive": "true",
    "Apollo-Require-Preflight": "false",
  },
})

export const apolloUploadClient = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache({ typePolicies }),
})
