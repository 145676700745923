import { graphql } from "msw"
import arrayOfLength from "utils/arrayOfLength"
import payoutFactory from "./payout.factory"
import { DateTime } from "luxon"
import { faker } from "@faker-js/faker"

faker.seed(123) // WHY: Keeps data consistent

const filterByStatus =
  (statuses) =>
  ({ node }) =>
    !statuses?.length || statuses.includes(node.status)

const filterByDateRange =
  ({ dateFrom, dateTo }) =>
  ({ node }) => {
    const payoutDate = DateTime.fromISO(node.createdAt)
    const dateFromDate = DateTime.fromISO(dateFrom)
    const dateToDate = DateTime.fromISO(dateTo)

    return (
      !dateFrom ||
      !dateTo ||
      (dateFromDate <= payoutDate && dateToDate >= payoutDate)
    )
  }

const edges = arrayOfLength(400).map(() => {
  const node = payoutFactory(faker)
  return {
    node,
    cursor: node.id,
  }
})
export default graphql.query("GetPayouts", (req, res, ctx) => {
  const { statuses, dateFrom, dateTo, first, after } = req.variables

  const filteredEdges = edges
    .filter(filterByStatus(statuses))
    .filter(filterByDateRange({ dateFrom, dateTo }))

  const startIndex = after
    ? filteredEdges.findIndex(({ cursor }) => cursor === after) + 1
    : 0

  const endIndex = first ? startIndex + first : filteredEdges.length
  const paginatedEdges = filteredEdges.slice(startIndex, endIndex)

  return res(
    ctx.delay(),
    ctx.data({
      payouts: {
        edges: paginatedEdges,
        pageInfo: {
          hasNextPage: endIndex < filteredEdges.length,
          hasPreviousPage: startIndex !== 0,
          startCursor: paginatedEdges[0]?.cursor || null,
          endCursor: paginatedEdges[paginatedEdges.length - 1]?.cursor || null,
        },
      },
    })
  )
})
