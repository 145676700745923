import Cookies from "universal-cookie"

const COOKIE_NAME = "token"

const cookies = new Cookies()
const options = { path: "/" }

export function getToken() {
  return cookies.get(COOKIE_NAME, options)
}

export function saveToken(token) {
  cookies.set(COOKIE_NAME, token, options)
}

export function deleteToken() {
  cookies.remove(COOKIE_NAME, options)
}
