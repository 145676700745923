import useEventListener from "./useEventListener"

/**
 * Lets us disable the scroll event within an input type="number"
 * Allowing the user to scroll up and down the page as expected
 * In the future, we may try to avoid this input type entirely
 */

const useDisableInputNumberScroll = () => {
  const disableInputNumberScroll = (_event) => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur()
    }
  }

  useEventListener({
    event: "wheel",
    rootElement: document,
    handler: disableInputNumberScroll,
  })
}

export default useDisableInputNumberScroll
