import { graphql } from "msw"

export default graphql.mutation("ChangePayoutStatus", (req, res, ctx) => {
  const { id, status } = req.variables
  return res(
    ctx.data({
      id,
      status,
    })
  )
})
