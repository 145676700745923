import { graphql } from "msw"

export default graphql.query("users", (_req, res, ctx) => {
  return res(
    ctx.data({
      users: {
        edges: [
          {
            node: {
              id: "5e4e8b02-6796-4396-b904-db199fd9149b",
              name: "Obi-Wan Kenobi",
              email: "obi-wan@kenobi.com",
              activatedAt: "2021-06-22 10:33:07.717777+00",
              requestedActivationAt: "2021-06-22 10:33:42.841+00",
            },
            cursor: "65ba2cca-00ac-47d7-a319-5143c47446c7",
          },
        ],
        pageInfo: {
          hasNextPage: false,
        },
      },
    })
  )
})
