import { UserRole } from "graphql/gql/graphql"
import { createContext, useContext, useEffect, useReducer } from "react"

type State = {
  id: string
  firstName: string
  lastName: string
  email: string
  role: string
  designerUsers: any[]
}

const initialState: State = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  designerUsers: [],
}

type UserContextValue = {
  removeAuthDetails: () => void
  setAuthDetails: (user: any) => void
  designerHandles: string[]
  designerIds: string[]
  isAdminUser: boolean
  isFinanceUser: boolean
  isServiceOrderUSUser: boolean
  isServiceOrderUKUser: boolean
  isServiceOrderEUUser: boolean
  isServiceOrderAUUser: boolean
  isDataEntryUser: boolean
  role: string
  userId: string
  userFirstName: string
  userLastName: string
}

const defaultValue: UserContextValue = {
  removeAuthDetails: () => {},
  setAuthDetails: () => {},
  designerHandles: [],
  designerIds: [],
  isAdminUser: false,
  isFinanceUser: false,
  isServiceOrderUSUser: false,
  isServiceOrderUKUser: false,
  isServiceOrderEUUser: false,
  isServiceOrderAUUser: false,
  isDataEntryUser: false,
  role: "",
  userId: "",
  userFirstName: "",
  userLastName: "",
}

export const UserContext = createContext<UserContextValue>(defaultValue)

const getDesignerIds = (state: State) =>
  state?.designerUsers?.map(({ designer }) => designer.id) || []
const getDesignerHandles = (state: State) =>
  state?.designerUsers?.map(({ designer }) => designer.handle) || []

const reducer = (
  _state: State,
  action: {
    type: "setAuthDetails" | "removeAuthDetails"
    payload?: State
  }
) => {
  switch (action.type) {
    case "setAuthDetails":
      return {
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        role: action.payload.role,
        designerUsers: action.payload.designerUsers || [],
      }
    case "removeAuthDetails":
      return {
        ...initialState,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const UserProvider = ({ children }) => {
  let localState = null
  if (typeof localStorage !== "undefined" && localStorage.getItem("userInfo")) {
    localState = JSON.parse(localStorage.getItem("userInfo") || "")
  }
  const [state, dispatch] = useReducer(reducer, localState || initialState)
  if (typeof localStorage !== "undefined") {
    useEffect(() => {
      localStorage.setItem("userInfo", JSON.stringify(state))
    }, [state])
  }

  const removeAuthDetails = () => {
    dispatch({
      type: "removeAuthDetails",
    })
  }

  const setAuthDetails = (user: any) => {
    dispatch({
      type: "setAuthDetails",
      payload: user,
    })
  }

  const meta = {
    isFinanceUser: state.role === "Finance",
  }

  const value = {
    removeAuthDetails,
    setAuthDetails,
    designerHandles: getDesignerHandles(state),
    designerIds: getDesignerIds(state),
    isAdminUser: state.role === UserRole.Admin,
    isFinanceUser: state.role === UserRole.Finance,
    isServiceOrderUSUser: state.role === UserRole.ServiceOrderUs,
    isServiceOrderUKUser: state.role === UserRole.ServiceOrderUk,
    isServiceOrderEUUser: state.role === UserRole.ServiceOrderEu,
    isServiceOrderAUUser: state.role === UserRole.ServiceOrderAu,
    isDataEntryUser: state.role === UserRole.DataEntry,
    role: state.role,
    userId: state.id,
    userFirstName: state.firstName,
    userLastName: state.lastName,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext<UserContextValue>(UserContext)
