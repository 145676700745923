import payoutLineFactory from "mocks/payoutLines/payoutLine.factory"
import arrayOfLength from "utils/arrayOfLength"

export default (faker) => ({
  totalAmount: faker.random.numeric(4),
  totalGrossEarnings: faker.random.numeric(4),
  totalGrossEarningsExcludingTaxes: faker.random.numeric(4),
  totalTaxCollected: faker.random.numeric(3),
  totalFinematterCommission: faker.random.numeric(3),
  totalFinematterVAT: faker.random.numeric(3),
  vatRate: 0.2,
  createdAt: new Date(faker.date.past()).toISOString(),
  paymentPeriod: new Date(faker.date.past()).toISOString(),
  designerId: faker.datatype.uuid(),
  designer: {
    name: faker.name.findName(),
  },
  designerAddress: JSON.stringify({
    line1: "Gothersgade 29",
    line2: null,
    line3: null,
    city: "Hovedstaden",
    zip: "1123",
    region: null,
    country: "Denmark",
  }),
  finematterAddress: JSON.stringify({
    line1: "4C Printing House Yard",
    line2: null,
    line3: null,
    city: "London",
    zip: "E2 7PR",
    region: null,
    country: "United Kingdom",
  }),
  generatedBy: faker.datatype.uuid(),
  id: faker.datatype.uuid(),
  paidAt: null,
  paidBy: null,
  reference: `${faker.random.numeric(4)}${faker.random.alpha(3)}`.toUpperCase(),
  status: faker.helpers.arrayElement(["Draft", "Pending", "Paid"]),
  payoutLines: arrayOfLength(
    faker.datatype.number({
      min: 5,
      max: 25,
    })
  ).map(() => payoutLineFactory(faker)),
})
