/**
 * Creates an array with the length of the number passed to it
 *
 * @param length The length you want the array to be
 * @returns An array of numbers starting at 0
 */

const arrayOfLength = (length = 0) =>
  length ? Array.from(Array(length).keys()) : []

export default arrayOfLength
